<template>
  <div :style="getElementStyle">
    <div v-if="isDefalutPosq">
      <el-row style="align-items: center">
        <label for="text" style="white-space: nowrap; margin-right: 5px">{{
          data.label
        }}</label>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        >
        </i>
        <el-row v-if="isActive || isDataTableField" class="setting-icon">
            <i class="el-icon-s-tools" @click="openSettings"></i>
        </el-row>
        <el-select
          v-model="data.default_value"
          placeholder="Select Currency"
          class="currency-select"
        >
          <el-option
            v-for="currency in CurrencyTypes"
            :key="currency.value"
            :label="`${currency.name} ${currency.value}`"
            :value="currency.value"
          />
        </el-select>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyTypesView",
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable"],
  computed: {
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getElementStyle() {
      let style = "";
      if (this.data.styles) {
        let borderType = this.data.styles.border_type || "solid";
        let borderSize = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let borderColor = this.data.styles.border_color || "";
        let background = this.data.styles.background || "";
        let labelColor = this.data.styles.label_color || "";

        style += `border: ${borderType} ${borderSize} ${borderColor};`;
        if (background) style += `background: ${background};`;
        if (labelColor) style += `color: ${labelColor};`;
      }
      return style;
    },
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  data() {
    return {
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
      ],
      isDefalutPosq: true,
    };
  },
};
</script>

<style lang="scss">
.currency-display {
  text-align: center;
}
.currency-type {
  color: gray;
}
.setting-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
